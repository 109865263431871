
import { defineComponent } from 'vue'
import axios from 'axios'
import { ElMessageBox, ElMessage, ElLoading } from 'element-plus'
import {
    getProcessDetail,
    ProcessDetailDto,
    getProcessWorkers,
    ProcessWorkersDto,
    payScreenType
} from '@/api/pay'

import {
    stopPay,
    applyBill,
    downloadBill,
    batchApplyBill,
    downloadPayrollRecordEle, batchApplyEle
} from '@/api/operation'

import { confirm } from '@/utils/util'
import { exportProcessDeatil } from '@/api/export'

import searchComponent from '@/components/search-component/index.vue'
import tableComponent from '@/components/table-component/index.vue'

import { statusOptions } from '../options'

export default defineComponent({
    components: {
        searchComponent,
        tableComponent
    },
    props: {
        id: {
            type: [String, Number],
            default: ''
        },
        basicInfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            searchList: {
                searchText: {
                    name: '搜索',
                    prop: 'searchText',
                    type: 'input',
                    placeholder: '请输入人员信息',
                    value: '',
                    defaultValue: ''
                },
                status: {
                    name: '发薪状态',
                    prop: 'status',
                    type: 'select',
                    placeholder: '请选择',
                    value: '',
                    defaultValue: '',
                    //
                    options: [],
                    defaultAttrs: {
                        label: 'dictLabel',
                        value: 'dictValue'
                    },
                    handleFocus(item: any) {
                        if (item.options.length) return
                        payScreenType('saas-pay-get-status', { loading: true }).then(res => {
                            item.options = res
                            const all = item.options.find((i: any) => i.dictLabel === '全部')
                            if (all) {
                                all.dictValue = ''
                            }
                        })
                    }
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            buttonList: [
                {
                    name: '一键申请微信/支付宝回单',
                    icon: 'export',
                    type: 'success',
                    fn: () => {
                        this.batchApplyWxBill()
                    }
                },
                {
                    name: '一键下载微信/支付宝回单',
                    icon: 'export',
                    type: 'success',
                    fn: () => {
                        this.batchDownloadBill()
                    }
                },
                {
                    name: '导出名单',
                    icon: 'export',
                    buttonType: 'export',
                    fn: () => {
                        this.onExport()
                    }
                },
                {
                    name: '停止发薪',
                    icon: 'export',
                    type: 'danger',
                    fn: () => {
                        this.onStopList()
                    }
                }
            ] as any,
            tableHead: [
                {
                    prop: 'index'
                },
                {
                    prop: 'userName',
                    label: '姓名'
                },
                {
                    prop: 'phone',
                    label: '手机号',
                    width: 120
                },
                {
                    prop: 'idCardNo',
                    label: '身份证号',
                    width: 170
                },
                {
                    prop: 'paymentAmount',
                    label: '发薪金额',
                    suffix: '元'
                },
                {
                    prop: 'statusMsg',
                    label: '操作状态'
                },
                {
                    prop: 'payTime',
                    label: '操作时间',
                    width: 160
                },
                {
                    prop: 'failReasonMsg',
                    label: '失败原因'
                },
                {
                    prop: 'operate',
                    label: '操作',
                    width: 100,
                    fixed: 'right'
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            tableData: [] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            selects: []
        }
    },
    watch: {
        id: {
            handler(newval) {
                if (newval) {
                    this.getData()
                }
            },
            immediate: true
        }
    },
    computed: {
        statusMap() {
            return (status: number) => {
                console.log(33333, status)
                const cur = statusOptions.find(item => item.value === status)
                return cur ? cur.label : ''
            }
        }
    },
    methods: {
        getRowKeys(row: any) {
            return row.id
        },
        handleSelectionChange(row: any) {
            this.selects = row.map((item: any) => item.id)
        },
        createQuery() {
            const query = {
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize
            } as any
            for (const key in this.searchList) {
                if (this.searchList[key].type === 'dateRange') {
                    query[`begin${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[0] : ''
                    query[`end${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[1] : ''
                } else {
                    query[(this.searchList[key]).prop] = this.searchList[key].value
                }
            }
            return query
        },
        handleSearch() {
            this.pageInfo.pageNum = 1
            this.getData()
        },
        handleSizeChange(ev: number) {
            console.log(111, ev)
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: number) {
            console.log(222, ev)
            this.pageInfo.pageNum = ev
            this.getData()
        },
        async getData() {
            const query = this.createQuery()
            query.processId = (this as any).id
            console.log(query, 'query')

            const res = await getProcessWorkers(query) as any
            console.log(33333, res)
            this.tableData = res.list || []
            this.pageInfo.total = res.total || 0
        },
        // 停止发薪
        async stopPay(id: string) {
            confirm('', '确认停止发薪吗').then(async () => {
                const msg = await stopPay(id)
                this.$message.success(msg)
                this.getData()
            })
        },
        // 申请回单
        async applyBill(batchNo: string, detailOrderNo: string, id: number) {
            await applyBill({
                batchNo,
                detailOrderNo,
                id
            })
            this.$message.success('回单申请成功，请耐心等待！')
            this.getData()
        },
        // 下载回单
        async downloadBill(batchNo: string, detailOrderNo: string, id: number) {
            const result = await downloadBill({
                batchNo,
                detailOrderNo,
                id
            })
            console.log(result, 'result')
            window.location.href = result
            this.$message.success('回单下载成功！')
        },
        async batchApplyWxBill() {
            await batchApplyBill({
                processId: (this as any).id,
                ids: this.selects.join(',')
            })
            this.$message.success('已提交批量生成！')
        },
        async batchDownloadBill() {
            this.downloadReceiptOld()
        },
        // 下载微信回单方法
        async downloadReceiptOld() {
            const result = await downloadPayrollRecordEle({
                ids: this.selects.join(',')
            })
            const loading = await ElLoading.service({
                fullscreen: true,
                text: '回单正在下载中，您可以在新界面继续操作'
            })
            const files = await axios({
                url:
                    'https://1403244436326619.cn-shanghai.fc.aliyuncs.com/2016-08-15/proxy/zip-service/zip-oss/',
                method: 'post',
                data: result
            })
            window.location.href = files.data
            await this.$message.success('导出成功！')
            await loading.close()
        },
        // 导出名单
        async onExport() {
            const result = await exportProcessDeatil({
                processId: (this as any).id as string,
                searchText: this.searchList.searchText.value,
                ids: this.selects.join(',')
            })
            window.location.href =
                process.env.VUE_APP_BASE_URL +
                '/common/download?delete=true&fileName=' +
                result

            this.$message.success('导出成功！')
        },
        onStopList() {
            if (!this.selects.length) {
                this.$message.error('请至少勾选一人！')
                return
            }
            this.stopPay(this.selects.join(','))
        }
    }
})
