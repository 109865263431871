
import { defineComponent } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import infoProject from './components/info-project.vue'
import detailPay from './components/detail-pay.vue'
export default defineComponent({
    components: {
        infoProject,
        detailPay
    },
    data() {
        return {
            id: '',
            basicInfo: {}
        }
    },
    mounted() {
        if (this.$route.query?.id) {
            this.id = atob(this.$route.query.id as any)
        }
    },
    methods: {
        handleBasicInfo(data: any) {
            this.basicInfo = data
        }
    }
})
