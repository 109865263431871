import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_project = _resolveComponent("info-project")!
  const _component_detail_pay = _resolveComponent("detail-pay")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { class: "container" }, {
    default: _withCtx(() => [
      _createVNode(_component_info_project, {
        id: _ctx.id,
        onBasicInfo: _ctx.handleBasicInfo
      }, null, 8, ["id", "onBasicInfo"]),
      _createVNode(_component_detail_pay, {
        class: "mg-t-20",
        id: _ctx.id,
        "basic-info": _ctx.basicInfo
      }, null, 8, ["id", "basic-info"])
    ]),
    _: 1
  }))
}