
import { defineComponent } from 'vue'
import {
    getProcessDetail,
    ProcessDetailDto,
    getProcessWorkers,
    ProcessWorkersDto,
    payScreenType
} from '@/api/pay'

export default defineComponent({
    components: {},
    props: {
        id: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        return {
            detail: {}
        }
    },
    watch: {
        id: {
            handler(newval) {
                if (newval) {
                    this.getData()
                }
            },
            immediate: true
        }
    },
    computed: {
        setDefault() {
            return (value: string) => {
                return value ?? '--'
            }
        }
    },
    methods: {
        getData() {
            getProcessDetail({
                id: this.id as any
            }).then((res: any) => {
                this.detail = res || {}
                this.$emit('basicInfo', this.detail)
            })
        }
    }
})
